import { useMemo } from "react";

import { PlayCircleFilled, StopFilled } from "@ant-design/icons";
import { useQuery } from "@apollo/client";
import { RVTask } from "@regal-voice/shared-types";
import { Form, Select, SelectProps } from "antd";
import { clsx } from "clsx";
import { chain, Many } from "lodash";
import { useSelector } from "react-redux";

import { useFlags } from "Services/FeatureFlagService";
import { AGENTS_QUERY_HARD_LIMIT, getAgentsForInternalTransfer } from "Services/marketing-api/agents/queries";
import { selectWorkerUri } from "Services/state/agent/AgentInformationSlice";
import { Agent } from "Types/Agent";

import { AgentAvailability, getAgentAvailabilityForTask } from "./helpers";

import styles from "./AgentsDropdown.module.scss";

export type AgentsDropdownProps = {
    placeholder?: string;
    showAvailableAgentsFirst?: boolean;
    showCurrentAgent?: boolean;
    "data-testid"?: string;
    task: RVTask | undefined;
    removeAiAgents?: boolean;
    showOptionTooltip?: boolean;
};

export interface AgentsDropdownOption {
    name: string;
    label: JSX.Element;
    value: string;
}

// needed so we can filter by both name and email
// otherwise the filter would only work on email
export const filterNameAndEmail: NonNullable<SelectProps["filterOption"]> = (inputValue, option) => {
    const agentOption = option as AgentsDropdownOption;
    const optionValue = agentOption?.value?.toLowerCase();
    const nameValue = agentOption?.name?.toLowerCase();
    if (!optionValue && !nameValue) {
        return false;
    }

    if (!inputValue) {
        return false;
    }
    const includes =
        (optionValue?.includes(inputValue.toLowerCase()) || nameValue?.includes(inputValue.toLowerCase())) ?? false;
    return includes;
};

type RenderAgentDropdownOptionProps = {
    agent: Agent;
    task?: RVTask;
    enableTransferToBusyAgents: boolean;
    showTooltip?: boolean;
};

export function renderAgentDropdownOption({
    agent,
    task,
    enableTransferToBusyAgents,
    showTooltip = true,
}: RenderAgentDropdownOptionProps): AgentsDropdownOption {
    const { name, email } = agent;

    const availability = getAgentAvailabilityForTask(agent, task, enableTransferToBusyAgents);

    return {
        name,
        value: email,
        label: (
            <div key={email} className={styles.menuItem} title={showTooltip ? `${name} (${email})` : undefined}>
                <span>
                    {availability === AgentAvailability.Available ? (
                        <PlayCircleFilled className={styles.online} />
                    ) : availability === AgentAvailability.Busy ? (
                        <PlayCircleFilled className={styles.muted} />
                    ) : (
                        <StopFilled className={styles.muted} />
                    )}
                </span>
                <div className={clsx("flex-column", styles.optionIdentifier)}>
                    <span>{name}</span>
                    <span className={styles.muted}>{email}</span>
                </div>
            </div>
        ),
    };
}

export function AgentsDropdown({
    placeholder = "Select Agent",
    showAvailableAgentsFirst = true,
    showCurrentAgent = false,
    removeAiAgents = false,
    showOptionTooltip = true,
    task,
    "data-testid": dataTestId,
}: AgentsDropdownProps) {
    const currentWorkerUri = useSelector(selectWorkerUri);
    const { enableTransferToBusyAgents } = useFlags();

    const { loading, data } = useQuery<{
        findAllAgents: {
            items: Agent[];
        };
    }>(getAgentsForInternalTransfer, {
        fetchPolicy: "network-only",
        variables: {
            queryParams: {
                page: 0,
                pageSize: AGENTS_QUERY_HARD_LIMIT,
                orderBy: [
                    {
                        key: "name",
                        value: "ASC",
                    },
                ],
            },
        },
    });

    const agentsDropdownOptions = useMemo(() => {
        const sort = showAvailableAgentsFirst
            ? {
                  fields: ["isActive", "isAvailable", "value"],
                  order: ["desc", "desc", "asc"] as Many<boolean | "asc" | "desc">,
              }
            : {
                  fields: ["value"],
                  order: ["asc"] as Many<boolean | "asc" | "desc">,
              };

        let sortedAgents = chain(data?.findAllAgents?.items ?? [])
            .uniqBy("twilioContactUri")
            .orderBy(sort.fields, sort.order)
            .value();

        if (!showCurrentAgent) {
            // this checks for `twilioSid` and `twilioContactUri`
            // are present in the agent transfer dropdown
            // but not in the manually override agent dropdown
            // !!agent.twilioSid &&
            // !!agent.twilioContactUri &&
            sortedAgents = sortedAgents.filter((agent) => agent.twilioContactUri !== `client:${currentWorkerUri}`);
        }

        if (removeAiAgents) {
            sortedAgents = sortedAgents.filter((agent) => !agent.isAiAgent);
        }

        return sortedAgents.map((agent) =>
            renderAgentDropdownOption({ agent, task, enableTransferToBusyAgents, showTooltip: showOptionTooltip })
        );
    }, [
        currentWorkerUri,
        data?.findAllAgents?.items,
        enableTransferToBusyAgents,
        showAvailableAgentsFirst,
        showCurrentAgent,
        removeAiAgents,
        task,
        showOptionTooltip,
    ]);

    return (
        <Form.Item
            name="reassignAgent"
            label="Select Agent"
            required
            rules={[{ required: true, message: "Agent is required." }]}
        >
            <Select
                allowClear
                showSearch
                placeholder={placeholder}
                size="large"
                getPopupContainer={(trigger) => trigger.parentNode}
                optionLabelProp="name"
                options={agentsDropdownOptions}
                filterOption={filterNameAndEmail}
                data-testid={dataTestId}
                loading={loading}
            />
        </Form.Item>
    );
}
