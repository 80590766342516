import { createSelector } from "@reduxjs/toolkit";
import { AppNotificationsConfig } from "@regal-voice/shared-types/lib/entities/Brand";
import { memoize } from "lodash";

import type { RootState } from "../Storage";
import type { BrandSettings } from "@regal-voice/shared-types";
import type { NormalizedAudioNotificationConfig } from "Types/Brand";

export const selectBrandRoot = (state: RootState) => state.brand;
export const selectBrand = createSelector(selectBrandRoot, (brandRoot) => brandRoot.brand);
export const selectBusinessHours = createSelector(selectBrand, (brand) => brand?.businessHours);

export const selectBrandMessagingConfig = (state: RootState) => state.brand.brand?.messagingConfig;
const DEFAULT_CONFIG: AppNotificationsConfig = {
    enabled: false,
};
export const selectBrandAppNotificationsConfig = createSelector(
    selectBrandMessagingConfig,
    (config) => config?.appNotifications || DEFAULT_CONFIG
);
export const selectBrandAppNotificationsConfigList = createSelector(
    selectBrandAppNotificationsConfig,
    ({ events: audioNotificationsByEvent }) => {
        const audioConfigs: Array<NormalizedAudioNotificationConfig> = [];
        if (typeof audioNotificationsByEvent === "object") {
            (Object.keys(audioNotificationsByEvent) as Array<keyof typeof audioNotificationsByEvent>).forEach(
                (event) => {
                    const audioNotificationsByTaskTitle = audioNotificationsByEvent[event];
                    if (typeof audioNotificationsByTaskTitle === "object") {
                        Object.entries(audioNotificationsByTaskTitle).forEach(([taskTitle, audio]) => {
                            const { url, loop, attribute } = audio;
                            if (url) {
                                audioConfigs.push({ url, loop, attribute, taskTitle, triggeringEvent: event });
                            }
                        });
                    }
                }
            );
        }
        return audioConfigs;
    }
);

export const selectBrandAvailableStatuses = createSelector(selectBrand, (brand) => brand?.availableStatuses ?? []);
export const selectBrandAvailableSkills = createSelector(selectBrand, (brand) => brand?.availableSkills ?? []);
export const selectBrandAccountSid = createSelector(selectBrand, (brand) => brand?.accountSid);
export const selectBrandDefaultFromNumber = createSelector(selectBrand, (brand) => brand?.defaultFromNumber);

export const selectBrandAdminSettings = createSelector(
    selectBrand,
    (brand) => (brand?.adminSettings || {}) as NonNullable<typeof brand>["adminSettings"]
);
export const selectBrandAdminSetting = createSelector(selectBrandAdminSettings, (settings) =>
    memoize((settingName: string) => settings[settingName])
);

export const selectReassignReasons = createSelector(selectBrand, (brand) => brand?.reassignReasons ?? []);

/**
 * @todo This needs to be converted into a proper selector (with `createSelector`), and separated
 *       into two selectors: one for the entire `brandSettings` object, and one for a specific
 *       setting.
 */
export function selectBrandSettings(settingName?: keyof BrandSettings) {
    return function (state: RootState): BrandSettings | BrandSettings[keyof BrandSettings] {
        const settings = state?.brand?.brand?.brandSettings || ({} as BrandSettings);
        return settingName ? settings[settingName] : settings;
    };
}
export function selectTwilioConfig(key?: string) {
    return function (state: RootState) {
        const settings = state?.brand?.brand?.twilioConfig || {};
        return key ? settings[key] : settings;
    };
}
export const selectDefaultAvailableStatus = createSelector(selectBrandAvailableStatuses, (statuses) => {
    const activitiesList = [...statuses.values()];
    let availableActivity = activitiesList.find((item) => {
        return item.name === "Available";
    });
    if (!availableActivity) {
        availableActivity = activitiesList.find((item) => {
            return item.available;
        });
    }
    return availableActivity;
});
