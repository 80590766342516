import { useState } from "react";

import { RVTask } from "@regal-voice/shared-types";
import { message } from "antd";
import { useSelector } from "react-redux";

import { updateTaskInTwilio } from "Services/ConversationsApiService";
import { renderErrorMessage } from "Services/LoggingService";
import { selectAgentEmail } from "Services/state/agent/AgentInformationSlice";
import { useRVDispatch } from "Services/state/Storage";
import { clearReservationBySid } from "Services/state/tasks/Thunks";

export function useTaskReassign({ task }: { task: RVTask | undefined }) {
    const dispatch = useRVDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const agentEmail = useSelector(selectAgentEmail);

    async function reassignTask(agent: string, reason: string) {
        setLoading(true);
        const attributes = {
            manualOverrideAgentEmail: agent,
            reporting: {
                reassignment: {
                    reason: reason,
                    reassignedBy: agentEmail as string,
                },
            },
        };
        try {
            // this shouldn't happen, but the component TaskCancellation
            // defined the `task` as possibly undefined
            if (!task) {
                throw new Error("Can't reassign task because no task was provided");
            }

            // update the task
            await updateTaskInTwilio(task.taskSid, { attributes });
            // remove the reservation for this task from the current user
            dispatch(clearReservationBySid(task.sid));

            message.success("Task reassigned.");
        } catch (e: any) {
            renderErrorMessage({ content: "Unable to reassign task", error: e });
        } finally {
            setLoading(false);
        }
    }

    return { loading, reassignTask };
}
