import { NullOr } from "@regal-voice/shared-types";
import { notification } from "antd";
import { intersection } from "lodash";

import { loadRegalAuthProfile } from "App/main/RegalAuthContext";
import { hardRedirect } from "App/routes";
import { apolloMutation } from "Services/ApolloClientService";
import { getLogger } from "Services/LoggingService";
import { updateAgent } from "Services/marketing-api/agents/mutations";
import { disconnect as disconnectSSE } from "Services/server-sent-events";
import { selectAgentInformation } from "Services/state/agent/AgentInformationSlice";
import { clearBrand, selectBrandAvailableStatuses } from "Services/state/brand";
import { reduxStore } from "Services/state/Storage";

import { getApiUrl } from "./ProxyConfig";
import { conversationsClient } from "./TwilioConversations.service";

import type { AuthState, RegalAuthProfile, ResetPasswordPayload, User, UserRole } from "Types/Auth";

export const logger = getLogger("Authentication");

type SignInWithSessionOptions = {
    sessionToken: string;
};

type CsrfTokenResponse = {
    csrfToken: string;
};

function getAuthUrl(path: string): string {
    return `${getApiUrl()}/auth${path}`;
}

export async function signInWithSession({ sessionToken }: SignInWithSessionOptions): Promise<RegalAuthProfile> {
    const response = await fetch(getAuthUrl("/session/create"), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ sessionToken }),
    });

    if (!response.ok) {
        throw new Error("Failed to create session cookie");
    }

    const profile: RegalAuthProfile = await response.json();
    return profile;
}

export async function signInWithCredentials(username: string, password: string): Promise<RegalAuthProfile> {
    const response = await fetch(getAuthUrl("/sign-in"), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
    });

    if (!response.ok) {
        throw new Error("Failed to sign in");
    }

    const profile: RegalAuthProfile = await response.json();
    return profile;
}

async function destroyRegalSessionCookie(): Promise<void> {
    await fetch(getAuthUrl("/session/destroy"), {
        method: "POST",
        credentials: "include",
    });
}

export type SetupProfileOptions = {
    sessionToken: string;
};

export function checkSessionRequest() {
    return fetch(getAuthUrl("/session/check"), {
        credentials: "same-origin",
    });
}

/**
 * DO NOT IMPORT THIS. JUST FOR TESTS
 */
export const __getInitialState = (): AuthState | null => {
    const regalAuth = loadRegalAuthProfile();
    if (!regalAuth) {
        // this case should only happen in the login bundle
        return null;
    }

    return {
        isAuthenticated: true,
        brand: regalAuth.brand.slug,
        user: {
            brand: regalAuth.brand.slug,
            email: regalAuth.user.email,
            family_name: regalAuth.user.lastName,
            given_name: regalAuth.user.firstName,
            name: `${regalAuth.user.firstName} ${regalAuth.user.lastName}`,
            sub: regalAuth.user.oktaUserId,
        },
    };
};

type AuthStateTypeOrEmpty = AuthState | Record<PropertyKey, never>;

export const authState: AuthStateTypeOrEmpty = __getInitialState() || {};

// eventually these should move to some auth state teardown logic
export function logoutCleanup(): void {
    disconnectSSE();
    reduxStore.dispatch(clearBrand());
    conversationsClient?.shutdown();
    sessionStorage.clear();
    notification.destroy();
}

export async function setAgentOffline(): Promise<void> {
    const state = reduxStore.getState();
    const agentInformation = selectAgentInformation(state);
    if (!agentInformation) {
        logger.error("Could not set agent to be offline because agent information was not found");
        return;
    }

    const availableStatuses = selectBrandAvailableStatuses(state);
    const currentStatusName = agentInformation.status;
    if (currentStatusName) {
        const currentActivity = availableStatuses.find((status) => status.name === currentStatusName);
        if (currentActivity && !currentActivity.available) {
            return;
        }
    }

    let offlineActivity = availableStatuses.find((status) => status.name === "Offline");

    if (!offlineActivity) {
        offlineActivity = availableStatuses.find((status) => !status.available);
    }

    if (!offlineActivity) {
        logger.error("Could not set agent to be offline because an offline status was not be found", {
            availableStatuses,
        });

        return;
    }

    try {
        await apolloMutation({
            mutation: updateAgent,
            variables: {
                updateUserData: {
                    status: offlineActivity.name,
                    twilioSid: agentInformation.workerSid,
                },
            },
        });
    } catch (error) {
        logger.error("Error attempting to update agent status to offline", {
            error,
            twilioWorkerSid: agentInformation.workerSid,
            status: offlineActivity.name,
        });
    }
}

// make this private so that it's only controlled by this file
let IS_CLIENT_LOGGING_OUT = false;

/**
 * Provides state of the logout process to prevent unnecessary actions
 */
export const isClientLoggingOut = () => IS_CLIENT_LOGGING_OUT;

export async function logout(): Promise<void> {
    IS_CLIENT_LOGGING_OUT = true;
    await setAgentOffline();
    logoutCleanup();
    await destroyRegalSessionCookie();
    // force redirect/code level refresh
    hardRedirect("/login");
}

export async function kickOut() {
    logoutCleanup();
    await destroyRegalSessionCookie();
    hardRedirect("/login?expired=true");
}

export const sendResetEmail = async (email: string): Promise<boolean> => {
    const response = await fetch(getAuthUrl("/send/reset-email"), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
    });

    if (!response.ok) {
        throw new Error("Failed to send reset email");
    }

    return true;
};
export const checkPasswordRecoveryToken = async (recoveryToken: string): Promise<{ email: string }> => {
    const response = await fetch(getAuthUrl("/verify/reset-token"), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ recoveryToken }),
    });

    if (!response.ok) {
        throw new Error("Failed to validate recovery token");
    }

    return await response.json();
};

export const resetPassword = async ({
    newPassword,
    recoveryToken,
}: ResetPasswordPayload): Promise<{ success: boolean }> => {
    const response = await fetch(getAuthUrl("/reset/password"), {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ newPassword, recoveryToken }),
    });

    const content = await response.json();

    if (!response.ok) {
        throw new Error("message" in content ? content.message : "Failed to reset password");
    }

    return content;
};

export const brandSlugFromAuth = () => authState.brand || "";

export function useBrandSlug(): string {
    return brandSlugFromAuth();
}

export function userHasRoles(user: NullOr<{ roles: string[] }>, roles: UserRole[]): boolean {
    if (!user) {
        return false;
    }
    return intersection(user.roles || [], roles).length > 0;
}

export function useUserInfo(): NullOr<User> {
    return authState.user || null;
}

export function isRegalVoiceUser(): boolean {
    const userEmail = authState.user?.email || "";
    return userEmail.endsWith("@regalvoice.com") || userEmail.endsWith("@regal.io");
}

let csrfToken: string | null = null;
export const CSRF_TOKEN_HEADER = "X-CSRF-Token";

export async function loadCsrfToken(): Promise<void> {
    const response = await fetch(getAuthUrl("/csrf-token"), {
        credentials: "include",
    });

    if (!response.ok) {
        throw new Error("Failed to load csrf token");
    }

    const data: CsrfTokenResponse = await response.json();
    ({ csrfToken } = data);
}

export function getCsrfToken(): string {
    return csrfToken || "";
}
