import { useCallback, useMemo, useState } from "react";

import { Tooltip } from "antd";

import { usePreferedAgentVMDropsData } from "ServerData/PrerecordedVoiceMessage/Hooks/usePrerecordedVoiceMessageData/usePrerecordedVoiceMessageData";
import { useFlags } from "Services/FeatureFlagService";

import { BUTTONS_MESSAGES } from "../../CallBar.consts";
import { CallBarButton } from "../CallBarButton";
import { VoicemailSettingsButton } from "./Components/VoicemailSettingsButton/VoicemailSettingsButton";

import styles from "./VoicemailDropButton.module.scss";

export interface VoicemailDropButtonProps {
    dropActive: boolean;
    campaignId?: string;
    voicemailDropDisabled: boolean;
    dropVoicemail: (id: string) => Promise<void>;
    contactPhone: string;
}

const AGENT_CALLBAR_LOCATION = "AgentCallbarQuery";
const VOICEMAIL_SETTINGS_TOOLTIP = "Change Voicemail Selection";
const NO_VOICEMAILS_AVAILABLE = "No Voicemails Available";
const NO_OTHER_VOICEMAILS_AVAILABLE = "No Other Voicemails";

export function VoicemailDropButton({
    campaignId,
    dropVoicemail,
    voicemailDropDisabled = false,
    dropActive,
    contactPhone,
}: VoicemailDropButtonProps): JSX.Element {
    const { hasPreRecordedVmDrops } = useFlags();
    const { loading, data } = usePreferedAgentVMDropsData({
        contactPhone,
        campaignId,
        invokedLocation: AGENT_CALLBAR_LOCATION,
    });

    const defaultVoicemailDrop = data?.[0];

    const [vmRecordingId, setVmRecordingId] = useState<string>("");
    const [open, setOpen] = useState(false);

    const toggleModal = useCallback(() => {
        if (!voicemailDropDisabled) {
            setOpen(!open);
        }
    }, [open, voicemailDropDisabled]);

    const handleSelectedVoicemailDrop = useCallback(async () => {
        try {
            await dropVoicemail(vmRecordingId);
            setOpen(false);
        } catch (error) {
            return Promise.reject(error);
        }
    }, [dropVoicemail, vmRecordingId]);

    // we are using a special endpoint, and [0] is always the default to use
    const handleDefaultVoicemailDrop = useCallback(async () => {
        await dropVoicemail(defaultVoicemailDrop?.id);
    }, [defaultVoicemailDrop, dropVoicemail]);

    const VMMessages = useMemo(() => {
        return {
            ...BUTTONS_MESSAGES.voicemailDrop,
            tooltip: () => `${defaultVoicemailDrop?.friendlyName}`,
        };
    }, [defaultVoicemailDrop]);

    const noDefaultVoicemails = !data || data?.length === 0;
    const noVoicemails = !data || data?.slice(1)?.length < 2;

    const voicemailButton = (
        <Tooltip title={noDefaultVoicemails ? NO_VOICEMAILS_AVAILABLE : undefined} placement="top">
            <div>
                <CallBarButton
                    id="voicemailDrop"
                    active={dropActive}
                    action={handleDefaultVoicemailDrop}
                    icon="voicemail"
                    disabled={voicemailDropDisabled || noDefaultVoicemails}
                    messages={VMMessages}
                />
            </div>
        </Tooltip>
    );

    if (!hasPreRecordedVmDrops) {
        return voicemailButton;
    }
    return (
        <div className={styles.container}>
            <VoicemailSettingsButton
                voicemailDropDisabled={voicemailDropDisabled}
                open={open}
                campaignId={campaignId}
                onVoicemailDrop={handleSelectedVoicemailDrop}
                voiceMailOptionData={data}
                loading={loading}
                dropActive={dropActive}
                setVmRecordingId={setVmRecordingId}
                toggleModal={toggleModal}
                noVoicemails={noVoicemails}
                tooltipMessage={noVoicemails ? NO_OTHER_VOICEMAILS_AVAILABLE : VOICEMAIL_SETTINGS_TOOLTIP}
            />
            {voicemailButton}
        </div>
    );
}
