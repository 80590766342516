import { useBrandAdminSetting, getBrandAdminSetting } from "Services/state/brand/BrandAdminSettings";

import type { TaskAttributesTitle } from "@regal-voice/shared-types";

/**
 * This will go to shared-types once the project is ready.
 */

export const TASK_PREFERENCES_SETTINGS = {
    tasksAutocomplete: "tasksAutocomplete",
    callsAutoAccept: "callsAutoAccept",
    tasksAutoOpenUrl: "tasksAutoOpenUrl",
} as const;

export type TaskPreferencesSettings = typeof TASK_PREFERENCES_SETTINGS;
export type TaskPreferencesSettingsName = keyof TaskPreferencesSettings;

export type TaskAutoCompleteTaskPreferenceSetting = {
    startSecs: number;
    countdownSecs: number;
};

export type CallsAutoAcceptTaskPreferenceSettings = {
    enabled: boolean;
};

export type TasksAutoOpenUrlTaskPreferenceSettings = {
    enabled: boolean;
};

type SettingsByName = {
    [TASK_PREFERENCES_SETTINGS.tasksAutocomplete]: TaskAutoCompleteTaskPreferenceSetting;
    [TASK_PREFERENCES_SETTINGS.callsAutoAccept]: CallsAutoAcceptTaskPreferenceSettings;
    [TASK_PREFERENCES_SETTINGS.tasksAutoOpenUrl]: TasksAutoOpenUrlTaskPreferenceSettings;
};

export type UseTaskPreferenceOptions<T extends TaskPreferencesSettingsName> = {
    name: T;
    taskTitle: TaskAttributesTitle;
};

export const useTaskPreference = <T extends TaskPreferencesSettingsName>({
    name,
    taskTitle,
}: UseTaskPreferenceOptions<T>): SettingsByName[T] => {
    const adminSetting = useBrandAdminSetting({
        name,
        variant: taskTitle,
    });

    return adminSetting as SettingsByName[T];
};

export const getTaskPreference = <T extends TaskPreferencesSettingsName>({
    name,
    taskTitle,
}: UseTaskPreferenceOptions<T>): SettingsByName[T] => {
    const adminSetting = getBrandAdminSetting({
        name,
        variant: taskTitle,
    });

    return adminSetting as SettingsByName[T];
};
