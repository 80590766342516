import {
    ChannelTaskAttributes,
    RVTask,
    TaskReservationStatus,
    TaskTaskStatus,
    TaskType,
    TaskTypeProp,
    TaskAttributesTitle,
} from "@regal-voice/shared-types";
import { NotificationsConfigEventNames } from "@regal-voice/shared-types/lib/entities/Brand";

import { Flag, flag$ } from "Application/thirdParty/launchDarkly";
import { audioElements, audioErrors, play } from "Components/elements/UserNotifier/helpers/audio.helpers";
import {
    FailedCallMessages,
    TOAST_DISPLAY_LENGTH_MED_SEC,
} from "Pages/agentDesktop/agentInterface/CallPanel/ActiveCall/helpers";
import {
    AGENT_WAIT_FOR_TASK_THRESHOLD,
    CHANNELS_TO_TRIGGER_OUTBOUND_CALL_CREATION as CALL_CHANNELS,
    DEFAULT_CHANNEL,
} from "Pages/agentDesktop/agentInterface/constants";
import { arePhoneNumbersEqual } from "Services/CommunicationService";
import { acceptTask, joinConference, outboundFromTask } from "Services/ConversationsApiService";
import { LoggerFn, LogLevel } from "Services/LoggingService";
import {
    selectAgentInformation,
    selectOnActiveCall,
    selectWorkerUri,
} from "Services/state/agent/AgentInformationSlice";
import { selectBrandAccountSid, selectBrandMessagingConfig } from "Services/state/brand";
import { reduxStore, RootState } from "Services/state/Storage";
import { getAttributes, isInboundConferenceCall } from "Services/Task.service";
import { getTaskPreference } from "Services/TasksPreferences";

import { ErrorPayload, KnownError, SuccessMessagePayload } from "../global-messages/GlobalMessages";
import { selectWaitingForTaskState } from "./Selectors";
import { ReservationCreatedSSETaskData } from "./SSESubscription";
import { clearWaitingForTaskState, TaskCreatedDate, TasksState } from "./TasksStateSlice";
import { logger as taskThunksLogger } from "./Thunks";

import type { SummaryTask, TaskAttributes } from "@regal-voice/shared-types";
import type { Device } from "@twilio/voice-sdk";

function getCreatedDate(task: RVTask, originalTaskCreatedDate?: string): number {
    // bug bash clean up, I think our typing is off somewhere.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return new Date(originalTaskCreatedDate ?? task.reservationCreatedDate!).getTime();
}

export function sortTasks(tasks: TasksState, taskCreatedDateBySid: TaskCreatedDate): RVTask[] {
    return Object.values(tasks).sort((left, right) => {
        const createdDateLeft = getCreatedDate(left, taskCreatedDateBySid[left.attributes.original_task_sid]);
        const createdDateRight = getCreatedDate(right, taskCreatedDateBySid[right.attributes.original_task_sid]);
        return createdDateRight - createdDateLeft;
    });
}

type KeyDiff = {
    added: string[];
    removed: string[];
};

export const finalReservationStatuses = ["canceled", "wrapping", "completed", "rescinded", "timeout"];

export async function handleNonLoopingAudioNotification(
    getState: () => RootState,
    task: RVTask,
    event: NotificationsConfigEventNames,
    logger: Record<LogLevel, LoggerFn>
) {
    const taskAttributes = task?.attributes as TaskAttributes;
    const state = getState();
    const messagingConfig = selectBrandMessagingConfig(state);
    if (!messagingConfig) {
        return;
    }

    const appNotifications = messagingConfig?.appNotifications || { enabled: false };
    const onActiveCall = selectOnActiveCall(state);
    const audioNotificationsEnabled = appNotifications?.enabled;
    const config: { url: string; loop?: boolean; attribute?: string } | undefined =
        appNotifications.events?.[event]?.[taskAttributes.title];

    // kebab case does not work well with v3 so we need to use the raw flag and trick the type here
    const audioNotificationsV3 = flag$("audio-notifications-v3" as Flag).getValue();

    if (
        audioNotificationsV3 ||
        !config ||
        !config.url ||
        onActiveCall ||
        !audioNotificationsEnabled ||
        (config.attribute && !taskAttributes[config.attribute as keyof TaskAttributes])
    ) {
        logger.debug(
            `handleAudioNotifications - Skipping audio notification for ${task.taskSid} because notifications should not be played`,
            {
                task,
                onActiveCall,
                audioNotificationsEnabled,
                configAttribute: config?.attribute,
                audioNotificationsV3,
            }
        );
        return;
    }

    try {
        await play(config.url, {
            loop: false,
            taskSid: task.taskSid,
            logContext: {
                source: "handleAudioNotifications",
                task,
            },
        });
        const audioElement = audioElements.get(config.url)?.audio;
        if (audioElement && !audioElement.paused && !audioElement.ended) {
            logger.debug(`handleAudioNotifications - Playing audio notification for ${task.taskSid}`, { task });
        } else {
            if (!audioErrors.has(config.url)) {
                logger.error(`handleAudioNotifications - Failed to play notifications for ${task.taskSid}`, { task });
            }
        }
    } catch (error) {
        logger.error(`handleAudioNotifications - Error playing audio notification for ${task.taskSid}`, {
            error,
            task,
        });
    }
}

export function keysDiff(oldTasks: Record<string, any>, newTasks: Record<string, any>): KeyDiff {
    const diff: KeyDiff = { added: [], removed: [] };
    for (const key of Object.keys(oldTasks)) {
        if (!newTasks[key]) {
            diff.removed.push(key);
        }
    }
    for (const key of Object.keys(newTasks)) {
        if (!oldTasks[key]) {
            diff.added.push(key);
        }
    }
    return diff;
}

/**
 * Determines if task should be ignored (the case when we are waiting for another priority task)
 * @param task
 */
export function shouldIgnoreTask(task: RVTask): boolean {
    return (
        isAgentWaitingForAnotherTask(task) &&
        isVoiceTask(task) &&
        (CALL_CHANNELS.includes(task.taskChannelUniqueName) ||
            (task.taskChannelUniqueName === DEFAULT_CHANNEL &&
                !!task.attributes.triggerCall &&
                !!task.attributes.autoAnswer))
    );
}

export function shouldAutoAcceptTask(task?: RVTask): boolean {
    if (!task?.attributes) {
        return false;
    }

    const isAutoAcceptInboundCall =
        flag$("autoAcceptInboundCallsInConversations").getValue() && task.attributes.title === "Incoming Call";
    const isProgressiveDialCall = task.attributes.taskType === "Progressive Dial";
    // Eventually we will move all auto accepting logic to the BE, not just incoming calls.
    // We're just starting here to limit the scope.
    if (isAutoAcceptInboundCall || isProgressiveDialCall) {
        return false;
    }
    // make sure we set undefined as false
    return !!task.attributes.autoAnswer;
}

export function taskIsAcceptedProgressiveDial(task: RVTask): boolean {
    return (
        task.status === "accepted" && task.taskStatus === "assigned" && task.attributes.taskType === "Progressive Dial"
    );
}

/**
 * Determines if this task should wait for a subsequent task to be received
 */
export function shouldWaitForSubsequentTask(task: RVTask): boolean {
    return task.taskChannelUniqueName === DEFAULT_CHANNEL && task.attributes.triggerCall && isVoiceTask(task);
}

/**
 * Determines if this agent is waiting for a subsequent task that is not this one.
 */
export function isAgentWaitingForAnotherTask(task: RVTask): boolean {
    checkIfAgentWaitingThresholdHasPassed();
    const { waiting, contactPhone, taskType } = selectWaitingForTaskState(reduxStore.getState());
    return (
        waiting &&
        (!arePhoneNumbersEqual(contactPhone as string, task.attributes.contactPhone) ||
            taskType !== task.attributes.taskType)
    );
} // Callback Request

/**
 * Determines if this agent was waiting for this task to be received
 * @param task
 */
export function isAgentWaitingForThisTask(task: RVTask): boolean {
    checkIfAgentWaitingThresholdHasPassed();
    const { waiting, contactPhone, taskType } = selectWaitingForTaskState(reduxStore.getState());
    return (
        waiting &&
        arePhoneNumbersEqual(contactPhone as string, task.attributes.contactPhone) &&
        taskType === task.attributes.taskType
    );
}

/**
 * Checks if the waiting state has been active for more than the threshold we set and if so, clears the waiting state.
 * Should not be used in any other file.
 */
export function checkIfAgentWaitingThresholdHasPassed(): void {
    const { waiting, waitingSince, contactPhone, taskType } = selectWaitingForTaskState(reduxStore.getState());
    if (waiting) {
        const { status } = selectAgentInformation(reduxStore.getState());
        const now = Date.now();
        const timeElapsed = now - (waitingSince as number);
        if (timeElapsed >= AGENT_WAIT_FOR_TASK_THRESHOLD) {
            taskThunksLogger.error(
                "UI was waiting for reservation to come and it didn't. UI will start auto accepting tasks again as a fallback",
                {
                    waitingForTaskForContactPhone: contactPhone,
                    waitingForTaskType: taskType,
                    waitThreshold: AGENT_WAIT_FOR_TASK_THRESHOLD,
                    agentStatus: status,
                }
            );
            const { dispatch } = reduxStore;
            dispatch(clearWaitingForTaskState());
        }
    }
}

// Signatures
export function isAgentSMSTask(task: RVTask<"unknown"> | undefined): boolean;
export function isAgentSMSTask<T extends TaskType>(
    attributes: (TaskTypeProp<T> & ChannelTaskAttributes<T>) | undefined
): boolean;

// Implementation
export function isAgentSMSTask<T extends TaskType>(
    taskOrAttributes: RVTask<"unknown"> | undefined | (TaskTypeProp<T> & ChannelTaskAttributes<T>)
): boolean {
    // If the argument is a task, we access its attributes
    if (typeof taskOrAttributes === "object" && "attributes" in taskOrAttributes) {
        return taskOrAttributes.attributes.taskType === "Agent SMS Task";
    } else {
        // If the argument is just attributes
        return taskOrAttributes?.taskType === "Agent SMS Task";
    }
}

// Signatures
export function isTaskCustomTask(task: RVTask<"unknown"> | undefined): boolean;
export function isTaskCustomTask<T extends TaskType>(
    attributes: (TaskTypeProp<T> & ChannelTaskAttributes<T>) | undefined
): boolean;
export function isTaskCustomTask(event: SummaryTask): boolean;

// Inplementation
export function isTaskCustomTask<T extends TaskType>(
    taskEventOrAttributes: RVTask<"unknown"> | undefined | ((TaskTypeProp<T> & ChannelTaskAttributes<T>) | SummaryTask)
): boolean {
    if (taskEventOrAttributes === undefined) {
        return false;
    }
    // If the argument is a task, we access its attributes
    if (typeof taskEventOrAttributes === "object" && "attributes" in taskEventOrAttributes) {
        return taskEventOrAttributes.attributes.taskType === "Custom Task";
        // if it is a summary event, we access its title
    } else if ("disposition" in taskEventOrAttributes) {
        return taskEventOrAttributes?.title === "Custom Task";
        // If the argument is just task attributes
    } else if (typeof taskEventOrAttributes === "object" && "journeyFriendlyId" in taskEventOrAttributes) {
        return taskEventOrAttributes?.taskType === "Custom Task";
    }
    return false;
}

export function isVoiceTask(task: RVTask): boolean {
    return task.attributes.title?.includes("Call");
}

// When we accept a new call while another call is in progress
// we want to disconnect the previous call
export function shouldDisconnectDevice(newTask?: RVTask<"unknown">, activeTask?: RVTask<"unknown">): boolean {
    if (!newTask || !activeTask || newTask.taskSid === activeTask.taskSid) {
        // first task or same task, nothing to disconnect
        return false;
    }

    // if they are both voice tasks, we need to disconnect the active task
    return isVoiceTask(newTask) && isVoiceTask(activeTask);
}

export function mapReservationCreatedSSEToRVTask({
    attributes,
    createdDate,
    priority,
    queueName,
    queueSid,
    reservationSid,
    sid,
    taskSid,
    status,
    taskStatus,
    taskChannelSid,
    taskChannelUniqueName,
    workflowName,
    workflowSid,
    reservationCreatedAt,
    reservationUpdatedDate,
    workerSid,
    reservationAcceptedAt,
}: ReservationCreatedSSETaskData): RVTask {
    const newTask: RVTask = {
        age: 0,
        attributes,
        dateCreated: createdDate,
        dateUpdated: createdDate,
        priority: priority,
        queueName,
        queueSid,
        reason: "",
        reservationSid,
        sid,
        taskSid,
        status,
        taskStatus,
        taskChannelSid,
        taskChannelUniqueName,
        timeout: 0,
        workflowName,
        workflowSid,
        routingTarget: "",
        defaultFrom: "",
        channelType: taskChannelUniqueName,
        createdDate,
        updatedDate: createdDate,
        reservationCreatedDate: reservationCreatedAt,
        reservationUpdatedDate,
        incomingTransferObject: {},
        outgoingTransferObject: {},
        workerSid,
        // https://regalvoice.atlassian.net/browse/RD-10225
        // for progressive dialer tasks we send one combined "reservation.created" SSE event
        // with the created and accepted reservation data
        // rather than sending a reservation.created and a reservation.accepted SSE messages that could arrive out of order.
        // so for these tasks we populate the "accepted at" from the "created" event (since there will be no "accepted" one)
        reservationAcceptedAt,
    };

    const taskPreferencesForAutoAcceptEnabled = flag$("taskPreferencesAutoAccept").getValue();
    if (newTask.attributes && !newTask.attributes.autoAnswer && taskPreferencesForAutoAcceptEnabled) {
        newTask.attributes = {
            ...newTask.attributes,
            autoAnswer:
                getTaskPreference({
                    name: "callsAutoAccept",
                    taskTitle: newTask.attributes.title as TaskAttributesTitle,
                }).enabled ?? false,
        };
    }

    return newTask;
}

export function mapRecentTaskToRVTask(
    reservation: { reservationSid: string; workerSid: string; createdAt: string; updatedAt: string },
    task: {
        attributes: Record<string, any>;
        createdAt: string;
        updatedAt: string;
        acceptedAt?: string;
        priority: string;
        taskQueueName: string;
        sid: string;
        assignmentStatus: "wrapping" | "canceled" | "completed" | "deleted" | "accepted" | "reserved";
        channel: string;
        queueSid: string;
    }
): RVTask {
    const {
        attributes,
        createdAt: taskCreatedAt,
        updatedAt,
        acceptedAt,
        priority,
        taskQueueName,
        sid: taskSid,
        assignmentStatus,
        channel,
        queueSid,
    } = task;
    const { reservationSid, workerSid, createdAt: reservationCreatedAt, updatedAt: reservationUpdatedAt } = reservation;

    return {
        attributes,
        dateCreated: taskCreatedAt,
        dateUpdated: updatedAt,
        priority: Number(priority),
        queueName: taskQueueName,
        queueSid,
        reason: "",
        reservationSid,
        sid: reservationSid,
        taskSid,
        status: assignmentStatus === "reserved" ? "pending" : (assignmentStatus as TaskReservationStatus),
        taskStatus: assignmentStatus as TaskTaskStatus,
        taskChannelUniqueName: channel,
        channelType: channel,
        createdDate: taskCreatedAt,
        updatedDate: updatedAt,
        reservationCreatedDate: reservationCreatedAt,
        reservationAcceptedAt: acceptedAt,
        reservationUpdatedDate: reservationUpdatedAt,
        workerSid,
        // None of the below are easy to get via rv-api.
        // They're not actually used anywhere in the UI, so mocking is fine.
        // I'm mocking at all because we're locked in to using full RVTask for redux.
        // Ideally we would change that type, but it's too big a lift for now.
        age: 0,
        taskChannelSid: "",
        timeout: 0,
        workflowName: "",
        workflowSid: "",
        routingTarget: "",
        defaultFrom: "",
        incomingTransferObject: {},
        outgoingTransferObject: {},
    };
}

export function shouldRemoveUnacceptableTask(acceptTaskErrorMessage: string, task: RVTask): boolean {
    const isGenericAcceptFailure = acceptTaskErrorMessage?.indexOf("cannot be updated to [accepted]") > -1;
    if (!isGenericAcceptFailure) {
        return false;
    }
    const isAlreadyAccepted = acceptTaskErrorMessage?.indexOf("[accepted] and cannot be updated to [accepted]") > -1;
    if (isAlreadyAccepted) {
        return false;
    }
    const isInWrapping = acceptTaskErrorMessage?.indexOf("[wrapping]") > -1;
    if (isInWrapping) {
        return false;
    }
    const isCompletedDefaultTask =
        task?.taskChannelUniqueName == "default" &&
        acceptTaskErrorMessage?.indexOf("[completed] and cannot be updated to [accepted]") > -1;
    if (isCompletedDefaultTask) {
        return false;
    }
    return true;
}

export async function acceptReservation(task: RVTask): Promise<{ success: boolean; errors?: ErrorPayload }> {
    try {
        const response = await acceptTask(task);
        const channel = task?.taskChannelUniqueName;
        if (channel == "default" || channel == "priority") {
            // TODO: move all channel names to constants
            taskThunksLogger.log(`Accepted ${channel} reservation`, {
                accepted: response?.success,
                reservationSid: task?.sid,
                taskSid: task?.taskSid,
                originalTaskSid: task?.attributes?.originalTaskSid,
            });
        }
        return { success: true };
    } catch (error: any) {
        if (shouldRemoveUnacceptableTask(error?.message, task)) {
            return { success: false, errors: { type: "TaskNotAvailable", error } };
        }
        return { success: false };
    }
}

export async function makeCall(
    task: RVTask
): Promise<{ success: boolean; errors?: ErrorPayload; message?: SuccessMessagePayload }> {
    if (!CALL_CHANNELS.includes(task.taskChannelUniqueName)) {
        return { success: false };
    }

    const agentContactUri = selectWorkerUri(reduxStore.getState()) || "";
    const agentInformation = selectAgentInformation(reduxStore.getState());
    const accountSid = selectBrandAccountSid(reduxStore.getState());
    const isInbound = isInboundConferenceCall(task);

    const { conferenceFriendlyId = "", contactPhone = "" } = getAttributes(task);
    if (!agentContactUri || !agentInformation.workerUri || !agentInformation.email || !accountSid) {
        return { success: false, errors: { type: "MissingAgentUri" } };
    }

    try {
        let response: { success: boolean; canContact?: boolean };
        if (isInbound) {
            response = await joinConference({
                agent: {
                    uri: agentInformation.workerUri,
                    email: agentInformation.email,
                    attributes: agentInformation?.attributes ?? {},
                },
                task,
                brand: {
                    accountSid,
                },
            });
        } else {
            response = await outboundFromTask({
                agent: {
                    uri: agentInformation?.workerUri,
                    email: agentInformation?.email,
                    attributes: agentInformation?.attributes ?? {},
                },
                task,
                brand: {
                    accountSid,
                },
            });
        }

        if (response.canContact === false) {
            return {
                success: true,
                message: { content: FailedCallMessages.blocked.messageDisplay, duration: TOAST_DISPLAY_LENGTH_MED_SEC },
            };
        }
        return { success: true };
    } catch (error: any) {
        let type: KnownError;
        if (isInbound) {
            if (error?.statusCode == 404 && task?.attributes?.title) {
                type = task.attributes.title == "Transfer Call" ? "CanceledTransfer" : "CallEnded";
            } else {
                type = "CallConnectionFailure";
            }
        } else {
            type = "CallCreationFailure";
        }

        return {
            success: false,
            errors: {
                type,
                error,
                extraData: {
                    agentContactUri,
                    conferenceFriendlyId,
                    contactPhone,
                    taskSid: task.taskSid,
                    isInbound,
                },
            },
        };
    }
}

export function getContactIdentifierForTask(task?: RVTask) {
    if (!task) {
        return;
    }
    const { profileId, contactPhone } = task.attributes;
    if (task.attributes.profileId) {
        return profileId;
    }
    return contactPhone;
}

export function checkIfDeviceIsAvailableForCallTask(
    task: RVTask,
    taskStatus: TaskReservationStatus,
    device: Device | undefined,
    fetchNewDeviceToken: () => Promise<void>
) {
    if (isVoiceTask(task) && taskStatus === "accepted" && (!device || device.state !== "registered")) {
        taskThunksLogger.error("Device is not registered while accepting a call task", {
            task,
            isDeviceDefined: !!device,
            deviceState: device?.state,
        });
        // refresh token which will trigger device registration
        fetchNewDeviceToken();
    }
}
