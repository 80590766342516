import { useCallback, useEffect, useMemo } from "react";

import { NotificationsConfigEventNames } from "@regal-voice/shared-types/lib/entities/Brand";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useAudio } from "react-use";

import {
    __msToWaitForSameEventNotifications,
    useAudioNotifications,
} from "App/main/AudioNotificationProvider/AudioNotificationProvider";
import { getLogger } from "Services/LoggingService";
import { selectOnActiveCall } from "Services/state/agent/AgentInformationSlice";

const logger = getLogger("Revamped Audio Notifications");

type PreloadedAudioPlayerProps = {
    src: string;
    loop?: boolean;
    attribute?: string;
    triggeringEvent: NotificationsConfigEventNames;
    taskTitle: string;
};

/**
 * Loads a particular audio notification into the DOM. The player handles its own debouncing to prevent spammy audio.
 * Stopping the audio has the option to only stop if the audio is looping, which is mostly the scenario we care about.
 * It handles stopping itself if the user goes on an active call.
 */
export function PreloadedAudioPlayer({
    src,
    loop = false,
    attribute,
    triggeringEvent,
    taskTitle,
}: PreloadedAudioPlayerProps) {
    const onActiveCall = useSelector(selectOnActiveCall);
    const { registerAudioPlayer } = useAudioNotifications();
    const [audio, , { play, pause, seek }] = useAudio({
        src,
        loop,
        autoPlay: false,
        crossOrigin: "anonymous",
    });
    const isAutoAnswer = attribute === "autoAnswer";

    const stopAudio = useCallback(
        (checkForLoop: boolean) => {
            if (checkForLoop && !loop) {
                return;
            }
            logger.log("Stopping audio", { triggeringEvent, taskTitle, attribute, checkForLoop, loop });
            pause();
            seek(0);
        },
        // useAudio's functions are not stable even though they work perfectly fine. We can't memoize them.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loop, triggeringEvent, taskTitle, attribute]
    );

    // useAudio's functions are not stable even though they work perfectly fine. We can't memoize them.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const playAudio = useMemo(() => debounce(play, __msToWaitForSameEventNotifications, { leading: true }), []);

    useEffect(() => {
        // only works for when a call gets connected on the current tab
        if (onActiveCall && !isAutoAnswer) {
            stopAudio(true);
        }
    }, [attribute, onActiveCall, isAutoAnswer, stopAudio, taskTitle, triggeringEvent]);

    useEffect(() => {
        const unregister = registerAudioPlayer({
            taskTitle,
            triggeringEvent,
            play: playAudio,
            isAutoAnswer,
            attribute,
            stop: stopAudio,
        });
        return unregister;
    }, [registerAudioPlayer, taskTitle, triggeringEvent, isAutoAnswer, attribute, loop, stopAudio, playAudio]);

    return audio;
}
