import { Tooltip } from "antd";

import { VoicemailSettingsPopover } from "./components/VoicemailSettingsPopover/VoicemailSettingsPopover";

import type { PrerecordedVoiceMessage } from "Types/Recordings";

import styles from "./VoicemailSettingsButton.module.scss";

export interface VoicemailSettingsButtonProps {
    open: boolean;
    campaignId?: string;
    dropActive: boolean;
    voiceMailOptionData: Array<PrerecordedVoiceMessage>;
    setVmRecordingId: (value: string) => void;
    loading: boolean;
    voicemailDropDisabled: boolean;
    tooltipMessage?: string;
    noVoicemails: boolean;
    onVoicemailDrop: () => Promise<void>;
    toggleModal: () => void;
}

export function VoicemailSettingsButton({
    open,
    campaignId,
    setVmRecordingId,
    loading,
    dropActive,
    voiceMailOptionData,
    voicemailDropDisabled,
    noVoicemails,
    onVoicemailDrop,
    tooltipMessage,
    toggleModal,
}: VoicemailSettingsButtonProps) {
    const shouldHideTooltip = voicemailDropDisabled || open;
    return (
        <Tooltip
            overlayClassName={styles.tooltip}
            title={shouldHideTooltip && !noVoicemails ? undefined : tooltipMessage}
            placement="top"
        >
            <div className={styles.popoverContainer}>
                <VoicemailSettingsPopover
                    open={open}
                    campaignId={campaignId}
                    setVmRecordingId={setVmRecordingId}
                    onVoicemailDrop={onVoicemailDrop}
                    loading={loading}
                    dropActive={dropActive}
                    voiceMailOptionData={voiceMailOptionData}
                    disabled={voicemailDropDisabled || noVoicemails}
                    toggleModal={toggleModal}
                />
            </div>
        </Tooltip>
    );
}
