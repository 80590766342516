import { useAudioNotifications } from "App/main/AudioNotificationProvider/AudioNotificationProvider";
import { useNotifications } from "Services/notifications";

import { useMonitorActiveConversations } from "./hooks/useMonitorActiveConversations/useMonitorActiveConversations";
import { useTaskSpecificAudio } from "./hooks/useTaskSpecificAudio";

export function UserNotifier(): JSX.Element {
    const { play: originalPlayTaskAudio } = useTaskSpecificAudio();
    const { playTaskAudio } = useAudioNotifications();
    useMonitorActiveConversations({ playTaskAudio: originalPlayTaskAudio, newPlayTaskAudio: playTaskAudio });
    useNotifications();
    return <></>;
}
