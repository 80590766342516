import { TaskAttributesTitle } from "@regal-voice/shared-types";
import { NotificationsConfigEventNames } from "@regal-voice/shared-types/lib/entities/Brand";
import { useSelector } from "react-redux";

import { useFlags } from "Services/FeatureFlagService";
import { getLogger } from "Services/LoggingService";
import { selectOnActiveCall } from "Services/state/agent/AgentInformationSlice";
import { selectBrandAppNotificationsConfig } from "Services/state/brand";

import { play, stopAll } from "../helpers/audio.helpers";

const logger = getLogger("User notifications");

export type playTaskAudioFunc = (
    eventName: NotificationsConfigEventNames,
    taskName: TaskAttributesTitle,
    taskSid: string,
    adtlInfo?: Record<string, any>,
    onComplete?: () => void
) => Promise<boolean>;

export function useTaskSpecificAudio(): {
    play: playTaskAudioFunc;
    stop: () => void;
    hasAudio: (eventName: NotificationsConfigEventNames, taskName: TaskAttributesTitle) => boolean;
} {
    const { userNotifierMonitoring } = useFlags();
    const messagingConfig = useSelector(selectBrandAppNotificationsConfig);
    const onActiveCall = useSelector(selectOnActiveCall);

    function hasAudio(eventName: NotificationsConfigEventNames, taskName: TaskAttributesTitle): boolean {
        return messagingConfig.enabled && !!messagingConfig.events?.[eventName]?.[taskName];
    }

    function getTaskAudioConfig(
        eventName: NotificationsConfigEventNames,
        taskName: TaskAttributesTitle
    ): { url?: string; loop?: boolean } {
        if (!messagingConfig.enabled) {
            logger.warn("Attempted to load audio with disabled notifications", { eventName, taskName });
            return {};
        }

        const config: { url: string; loop?: boolean } | undefined = messagingConfig.events?.[eventName]?.[taskName];

        if (!config) {
            logger.log("No audio configuration found", { eventName, taskName });
            return {};
        }

        return config;
    }

    // Returns true if actively looping, otherwise false
    async function doPlay(
        eventName: NotificationsConfigEventNames,
        taskTitle: TaskAttributesTitle,
        taskSid: string,
        adtlInfo?: Record<string, any>,
        onComplete?: () => void
    ): Promise<boolean> {
        if (!messagingConfig.enabled) {
            logger.warn("Attempted to play with disabled notifications");
            return false;
        }

        const config = getTaskAudioConfig(eventName, taskTitle);
        // use presence of URL to determine if we actually have audio to play
        if (config.url && (!onActiveCall || !config.loop)) {
            userNotifierMonitoring && logger.log("Playing sound", { eventName, taskTitle, ...adtlInfo });
            await play(config.url, {
                loop: config.loop,
                logContext: { eventName, taskTitle, ...adtlInfo },
                onComplete,
                taskSid,
            });
            userNotifierMonitoring && logger.log("Played sound", { eventName, taskTitle, ...adtlInfo });
            return true;
        }
        return false;
    }

    return {
        play: doPlay,
        stop: stopAll,
        hasAudio,
    };
}
